import React, { Component } from 'react';
import { auth, db } from '../../firebase';

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  location: 'poznan',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.getLocation = this.getLocation.bind(this);
    this.state = { ...INITIAL_STATE, displayAlert: false };
  }

  onSubmit = (event) => {
    const {
      location,
      email,
      passwordOne,
      passwordTwo
    } = this.state;

    if (passwordOne !== passwordTwo) {
      var error = new Error("Podane hasła nie są identyczne.")
      this.setState(updateByPropertyName('error', error));
    } else
      auth.doCreateUserWithEmailAndPassword(email, passwordOne)
        .then((result) => {
          db.createUser(result.user.uid, location, "mobile")
            .then(() => {
              this.setState(() => ({ ...INITIAL_STATE, displayAlert: true }));
            }).catch(error => {
              this.setState(updateByPropertyName('error', error));
            });
        }).catch((error) => {
          console.log(error)
          if (error.code === 'auth/email-already-in-use')
            error.message = "Konto z podanym adresem e-mail już istnieje."
          else if (error.code === 'auth/weak-password')
            error.message = "Podane hasło musi mieć co najmniej 6 znaków."
          else
            error.message = "Podany adres e-mail jest niepoprawny."

          this.setState(updateByPropertyName('error', error));
        })

    event.preventDefault();
  }

  getLocation(event) {
    this.setState({
      location: event.target.value
    })
  }

  render() {
    const {
      location,
      email,
      passwordOne,
      passwordTwo,
      error,
      displayAlert
    } = this.state;

    const locations = JSON.parse(localStorage.getItem('locations'))

    return (
      <div>
        <div>
          {displayAlert ?
            <label className='reset-password-alert-label'
              onAnimationEnd={() => this.setState({ displayAlert: false })}>Zaproszenie zostało wysłane.
              </label>
            : null}
        </div>
        <label className='add-mobile-title'>Dodaj użytkownika mobilnego.</label>
        <form onSubmit={this.onSubmit}>
          <div className='add-mobile-labels'>
            <label className='add-mobile-email-label'>Adres e-mail</label> <br />
            <label className='add-mobile-label'>Hasło</label> <br />
            <label className='add-mobile-label'>Potwierdź hasło</label> <br />
            <label className='add-mobile-label'>Lokalizacja</label> <br />
          </div>
          <div>
            <input
              className='account-textfield'
              value={email}
              onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
              type="text"
              placeholder="Podaj adres e-mail"
            /> <br />
            <input
              className='account-textfield'
              value={passwordOne}
              onChange={event => this.setState(updateByPropertyName('passwordOne', event.target.value))}
              type="password"
              placeholder="Wprowadź hasło"
            /> <br />
            <input
              className='account-textfield'
              value={passwordTwo}
              onChange={event => this.setState(updateByPropertyName('passwordTwo', event.target.value))}
              type="password"
              placeholder="Potwierdź hasło"
            />
            <div>
              {locations.length > 1
                ?
                <select className='account-select' value={location} onChange={this.getLocation}>
                  <option value={locations[0]}>Poznań</option>
                  <option value={locations[1]}>Piaseczno</option>
                </select>
                :
                () => this.setState({ location: locations[0] })
              }
            </div>
          </div>
          <button className='register-mobile-button' type="submit">
            Wyślij zaproszenie
        </button> <br />
          {error && <label className='account-error-label'>{error.message}</label>}
        </form>
      </div>
    );
  }
}

export {
  SignUpForm,
};