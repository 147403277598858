import React from 'react';
import withAuthorization from "../Session/withAuthorization";
import { compose } from "recompose";
import { db, storage } from '../../firebase';
import './employee.css';
import { inject, observer } from 'mobx-react';
import { ImageModal } from '../AddEmployee/ImageModal';
import b64toBlob from 'b64-to-blob';

@inject('employeesStore')
@observer
export class Employee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imageUrl: null,
            image: null,
            imageName: null,
            assessmentResult: 0,
            editMode: false,
            name: null,
            surname: null,
            position: null,
            department: null,
            localization: null,
            showModal: false,
            error: null
        }
        this.removeEmployee = this.removeEmployee.bind(this);
        this.editFunction = this.editFunction.bind(this);
        this.saveFunction = this.saveFunction.bind(this);
    }

    componentDidMount() {
        this.getEmployeeImage(this.props.employee.imageName)
    }

    getEmployeeImage(imageName) {
        storage.getEmployeeImage(imageName).then((url) => {
            this.setState({
                imageUrl: url
            })
        })
    }

    removeEmployee() {
        const { employee, department, employeesStore } = this.props;
        const localizations = JSON.parse(localStorage.getItem('locations'));
        localizations.forEach((location) => {
            db.removeEmployee(location, department, employee.id)
                .then(() => {
                    if (department === 'sales') {
                        employeesStore.removeSaleEmployee(employee)
                    } else if (department === 'service') {
                        employeesStore.removeServiceEmployee(employee)
                    }
                })
        });
        storage.removeEmployeeImage(employee.imageName);
    }

    editFunction() {
        const { employee,
            department } = this.props;
        this.setState({
            editMode: true,
            name: employee.name,
            surname: employee.surname,
            department: department,
            position: employee.position,
            localization: employee.location
        })

    }

    savePhoto = (photo) => {
        this.setState({
            imageName: photo.imageName,
            image: photo.image
        })
    }

    saveFunction() {
        const { name, surname, department, position, imageName, image } = this.state;
        const { employee, employeesStore } = this.props;
        const oldDepartment = this.props.department;
        if (oldDepartment !== department) {
            db.removeEmployee(employee.location, oldDepartment, employee.id)
        }
        if (this.state.localization !== employee.location) {
            db.removeEmployee(employee.location, department, employee.id)
        }
        if (imageName !== employee.imageName && imageName !== null) {
            storage.removeEmployeeImage(employee.imageName);
        }

        if (imageName === null && image === null) {
            db.updateEmployee(name, surname, employee.imageName, position, department, this.state.localization, employee.id, employee.assessment);
            employeesStore.setSalesList([])
            employeesStore.setSerivceList([])
            this.props.updateList()
        } else {
            this.uploadImage().then(() => {
                db.updateEmployee(name, surname, imageName, position, department, this.state.localization, employee.id, employee.assessment);
                employeesStore.setSalesList([])
                employeesStore.setSerivceList([])
                this.props.updateList()
            }).catch((error) => {
                this.setState({ error: error })
            })
        }

        this.setState({ editMode: false })

    }

    uploadImage() {
        return new Promise((resolve, reject) => {
            const { image } = this.state;
            const { imageName } = this.state;
            if (imageName === null && image === null) {
                const error = new Error("Zdjęcie nie zostało dodane")
                reject(error)
            } else {
                var imageData = image.substring(image.indexOf(',') + 1);
                var contentType = image.substring(image.indexOf(':') + 1, image.indexOf(';'));
                var convertedImage = b64toBlob(imageData, contentType);
                storage.uploadEmployeeImage(convertedImage, imageName).then(() => {
                    resolve()
                })
            }
        })
    }

    render() {
        const { employee,
            department } = this.props;
        const departmentValue = department === "sales" ? "Sprzedaż" : 'Serwisowy';
        const { editMode, image } = this.state;
        const userRole = JSON.parse(localStorage.getItem('userRole'));
        const locations = JSON.parse(localStorage.getItem('locations'))
        const displayedImage = image !== null ? image : this.state.imageUrl
        return (
            <div className='employee-content'>
                {userRole !== 'mobile' ?
                    <div className={'employee-actions-buttons'}>
                        {editMode ? <button className={'edit-employee-button'} onClick={this.saveFunction}>Zapisz</button> :
                            <button className={'edit-employee-button'} onClick={this.editFunction}>Edytuj</button>}
                        {editMode ? <button className={'remove-employee-button'} onClick={() => this.setState({ editMode: false, image: null, imageName: null })}>Anuluj</button> :
                            <button className={'remove-employee-button'} onClick={this.removeEmployee}>Usuń</button>}
                    </div> : null
                }
                {editMode ? <img className={'employee-image'} alt="" src={displayedImage} height={160} width={160} onClick={() => this.setState({ showModal: true })} /> :
                    <img className={'employee-image'} alt="" src={this.state.imageUrl} height={160} width={160} />}
                <ImageModal photo={this.savePhoto} showModal={this.state.showModal} closeModal={() => this.setState({ showModal: false })} />
                <div className={'employee-info'}>
                    <div className='employee-labels'>
                        <label>Imię</label> <br />
                        <label>Nazwisko</label> <br />
                        <label>Dział</label> <br />
                        <label>Stanowisko</label> <br />
                        <label>Lokalizacja</label>
                    </div>
                    {!editMode ? <div className='employee-values'>
                        <label>{employee.name}</label> <br />
                        <label>{employee.surname}</label> <br />
                        <label>{departmentValue}</label> <br />
                        <label>{employee.position}</label> <br />
                        <label>{employee.location === 'poznan' ? 'Poznań' : "Piaseczno"} </label>
                    </div> :
                        <div className='employee-edit-mode'>
                            <input required type="text" className='edit-textfield' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} /><br />
                            <input required type="text" className='edit-textfield' value={this.state.surname} onChange={(e) => this.setState({ surname: e.target.value })} /><br />
                            <select className='edit-textfield' onChange={(e) => this.setState({ department: e.target.value })} value={this.state.department}>
                                <option value="sales">Sprzedaży</option>
                                <option value="service">Serwisowy</option>
                            </select><br />
                            <input required type="text" className='edit-textfield' value={this.state.position} onChange={(e) => this.setState({ position: e.target.value })} /><br />
                            {locations !== null && locations.length === 2 ? <select className='edit-textfield' onChange={(e) => this.setState({ localization: e.target.value })} value={this.state.localization}>
                                <option value="poznan">Poznań</option>
                                <option value="piaseczno">Piaseczno</option>
                            </select> : <label>{employee.location === 'poznan' ? 'Poznań' : "Piaseczno"} </label>}<br />
                        </div>}
                </div>
            </div>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition),
)(Employee);