import { db } from './firebase';

export const doCreateEmployee = (name, surname, imageName, position, department, location) => {
    var newRef = db.ref().child(location).child('employeeList').child(department).push();
    newRef.set({
        id: newRef.key,
        surname,
        name,
        imageName,
        position,
        location
    });
}

export const updateEmployee = (name, surname, imageName, position, department, location, id, assessment) => {
    if (assessment === undefined) {
        assessment = null;
    }
   db.ref().child(location).child('employeeList').child(department).child(id).update({
        id: id,
        assessment,
        surname,
        name,
        imageName,
        position,
        location
    })
}

export const getSales = (location) =>
    db.ref().child(location).child('employeeList').child('sales');

export const getServiceEmployees = (location) =>
    db.ref().child(location).child('employeeList').child('service');

export const getLocationsForUser = (userId) =>
    db.ref().child('users').child(userId).child('localization');

export const getUserInformation = (location, department, employeeId) =>
    db.ref().child(location).child('employeeList').child(department).child(employeeId);

export const removeEmployee = (location, department, employeeId) =>
    db.ref().child(location).child('employeeList').child(department).child(employeeId).set(null);

export const getUserRole = (userId) => 
    db.ref().child('users').child(userId).child('role');

export const createUser = (userId, location, role) => {
    var piaseczno = location === 'piaseczno'
    var poznan = location === 'poznan'
    return db.ref().child('users').child(userId).set({
        localization: [location],
        piaseczno: piaseczno,
        poznan: poznan,
        role: role
    })
}

