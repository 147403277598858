import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


const prodConfig = {
  apiKey: "AIzaSyAr-mes2ctosU2zkGgpfvMJH0tZK1mX9JA",
  authDomain: "test-cb310.firebaseapp.com",
  databaseURL: "https://test-cb310.firebaseio.com",
  projectId: "test-cb310",
  storageBucket: "test-cb310.appspot.com",
  messagingSenderId: "256257278671"
};

const devConfig = {
  apiKey: "AIzaSyAr-mes2ctosU2zkGgpfvMJH0tZK1mX9JA",
  authDomain: "test-cb310.firebaseapp.com",
  databaseURL: "https://test-cb310.firebaseio.com",
  projectId: "test-cb310",
  storageBucket: "test-cb310.appspot.com",
  messagingSenderId: "256257278671"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

var firebaseApp = firebase.initializeApp(config);
var inviteApp = firebase.initializeApp(config, "Invite")

const db = firebaseApp.database();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();
const sessionPersistence = firebase.auth.Auth.Persistence.SESSION;
const authInvite = inviteApp.auth();

export {
  db,
  auth,
  storage,
  sessionPersistence,
  authInvite
};
