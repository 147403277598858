import React from 'react';
import { compose } from 'recompose';
import { auth, db } from '../../firebase'
import PasswordChangeForm from '../PasswordChange';
import withAuthorization from '../Session/withAuthorization';
import { SignUpForm } from '../SignUp';
import './account.css'

class AccountPage extends React.Component {

  constructor() {
    super();
    this.inviteUser = this.inviteUser.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.state = {
      location: 'poznan',
      email: '',
      error: null,
      displayAlert: false
    }
  }

  inviteUser(event) {
    const { email } = this.state;
    const { location } = this.state;
    const generatedPassword = "GeneratedPassword" + Date.now()
    auth.doCreateUserWithEmailAndPassword(email, generatedPassword)
      .then((result) => {
        db.createUser(result.user.uid, location, "locationAdmin")
          .then(() => {
            auth.doPasswordReset(email)
              .then(() => {
                this.setState({
                  email: '',
                  location: 'poznan',
                  error: null,
                  displayAlert: true
                })
              }).catch((error) => {
                this.setState({ error: error })
              })
          }).catch((error) => {
            this.setState({ error: error })
          })
      }).catch((error) => {
        if (error.code === 'auth/email-already-in-use')
          error.message = "Konto z podanym adresem e-mail już istnieje."
        else
          error.message = "Podany adres e-mail jest niepoprawny."
        this.setState({ error: error })
      })

    event.preventDefault();
  }

  getLocation(event) {
    this.setState({
      location: event.target.value
    })
  }

  getEmail(event) {
    this.setState({
      email: event.target.value
    })
  }

  render() {
    const locations = JSON.parse(localStorage.getItem('locations'))
    const userRole = JSON.parse(localStorage.getItem('userRole'));
    return (
      <div className='account-settings'>
        <PasswordChangeForm />
        <div>
          {userRole === 'admin' ? <AdminPanel locations={locations}
            onSubmit={this.inviteUser}
            getLocation={this.getLocation}
            locationValue={this.state.location}
            getEmail={this.getEmail}
            emailValue={this.state.email}
            error={this.state.error} 
            successAlert={this.state.displayAlert}
            animationFinished={() => this.setState({displayAlert: false})}/>
            : null
          }
        </div>
        {userRole !== 'mobile' ?
          <div>
            <SignUpForm />
          </div> :
          null
        }
      </div>
    )
  }
}

const AdminPanel = ({ locations, onSubmit, getLocation, locationValue, getEmail, emailValue, error, successAlert, animationFinished }) =>
  <div>
      {successAlert ? 
      <p className='invate-localization-admin-alert-label'
        onAnimationEnd={animationFinished}>Zaproszenie zostało wysłane.
              </p>
               : null}
    <label className="invate-user-title">Zaproś użytkownika.</label>
    <div>
      <div className='invate-user-labels'>
        <label className='localization-label'>Lokalizacja</label> <br />
        <label className='email-label'>Adres e-mail</label>
      </div>
      <div className='invate-user-values'>
        <select className='account-select' value={locationValue} onChange={getLocation}>
          <option value={locations[0]}>Poznań</option>
          <option value={locations[1]}>Piaseczno</option>
        </select>
      </div>
    </div>
    <form onSubmit={onSubmit}>
      <input
        className='account-textfield'
        value={emailValue}
        onChange={getEmail}
        type="text"
        placeholder="Podaj adres e-mail"
      /> <br />
      <button className='send-inviate-button' type="submit">
        Wyślij zaproszenie
      </button> <br />
      {error && <label className='account-error-label'>{error.message}</label>}
    </form>
  </div>


const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition)
)(AccountPage);