import React, { Component } from 'react';
import { compose } from 'recompose';

import withAuthorization from '../Session/withAuthorization';
import { db } from '../../firebase';
import * as jsPDF from 'jspdf'
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './employee-list.css';
import 'jspdf-autotable';
import * as fonts from '../../constants/fonts';
import { Employee } from '../Employee/employee';
import unselectedArror from '../../assets/unselected-arrow.png'

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/minimal-example.css';

class Home extends Component {
    constructor() {
        super();
        this.generateRaport = this.generateRaport.bind(this);
        this.handleFilterRadioButtons = this.handleFilterRadioButtons.bind(this);
        this.handleLocalizationRadioButtons = this.handleLocalizationRadioButtons.bind(this);
        this.handleDepartment = this.handleDepartment.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.state = {
            salesAssesmentResults: [],
            serviceAssesmentResults: [],
            department: "sales",
            timePeriod: "all",
            location: "all",
            displayAlert: false,
        }
    }

    componentDidMount() {
        const { employeesStore } = this.props;
        employeesStore.setSalesList([]);
        employeesStore.setSerivceList([]);
        this.setState({
            salesAssesmentResults: [],
            serviceAssesmentResults: []
        })
        this.getEmployeesForUser();
    }

    getEmployeesForUser(location) {
        const locations = location === undefined ? JSON.parse(localStorage.getItem('locations')) : location
        if (locations !== null)
            locations.forEach((item) => {
                this.getSales(item);
                this.getServiceEmployees(item);
            });
    }

    generateRaport() {
        const { employeesStore } = this.props;
        const { department } = this.state;
        const { timePeriod } = this.state;

        var doc = new jsPDF()
        var columns = [{ title: "Imie", dataKey: "name" },
        { title: "Nazwisko", dataKey: "surname" },
        { title: "Wynik", dataKey: "result" }]

        doc.addFileToVFS("PTSans.ttf", fonts.PTSans);
        doc.addFont('PTSans.ttf', 'PTSans', 'normal');

        doc.setFont('PTSans');
        doc.setFontSize(20)

        var title = ""

        if (timePeriod === 'all')
            title = 'Raport generalny'
        else if (timePeriod === 'week')
            title = 'Raport tygodniowy'
        else if (timePeriod === 'moth')
            title = 'Raport miesięczny'
        else if (timePeriod === '3-months')
            title = 'Raport z 3 miesięcy'

        if (department === 'service') {
            title = title + ' - dział serwisowy'
            var service = this.joinResults(this.state.serviceAssesmentResults, employeesStore.serviceList)
            doc.autoTable(columns, service, {
                styles: { font: "PTSans" }, margin: { top: 30 }
            })
        } else if (department === 'sales') {
            title = title + ' - dział sprzedaży'
            var sales = this.joinResults(this.state.salesAssesmentResults, employeesStore.salesList)
            doc.autoTable(columns, sales, {
                styles: { font: "PTSans" }, margin: { top: 30 }
            })
        }
        doc.text(title, 20, 20)
        const dateNow = new Date().toLocaleDateString('pl')
        doc.save(dateNow + '-raport.pdf')
    }


    joinResults(serviceResults, employeeList) {
        const arr1Map = serviceResults.reduce((map, item) => map.set(item.id, item.result), new Map());
        const result = employeeList.map((item) => (Object.assign({
            result: arr1Map.get(item.id)
        }, item)));
        return result
    }

    calculateEmployeeRate(employeeId, assessment) {
        var rating = 0;
        var nowDate = Date.now();
        var assessmentLength = 0;
        if (assessment !== undefined) {
            Object.keys(assessment).forEach(key => {
                var date = assessment[key].date * 1000
                if (date !== undefined && date <= nowDate) {
                    rating = (rating + assessment[key].rate);
                    assessmentLength += 1
                }
            });
            if (assessmentLength !== 0) {
                var averageRating = rating / assessmentLength;
                var roundedResult = Math.round(averageRating * 100) / 100;
                var result = {
                    id: employeeId,
                    result: roundedResult.toPrecision(3)
                };
                return result
            }
        }
    }

    filerListByRate(employeeList, assesmentResults) {
        assesmentResults.sort((a, b) => a.result - b.result);
        var sortedIds = [];
        Object.keys(assesmentResults).forEach(key => {
            sortedIds.push(assesmentResults[key].id)
        });
        if (sortedIds.length !== 0)
            employeeList.sort((a, b) => {
                return sortedIds.indexOf(b.id) - sortedIds.indexOf(a.id)
            });
    }

    filterByTimePeriod(radioValue, employeeList, resultStore) {
        const nowDate = new Date();
        var serviceEmployeesResults = [];
        var salesEmployeesResults = [];
        employeeList.map(employee => {
            var result = [];
            if (employee.assessment !== undefined) {
                Object.keys(employee.assessment).forEach(key => {
                    const assessmentDate = new Date(employee.assessment[key].date * 1000);
                    if (!isNaN(assessmentDate)) {
                        if (radioValue === '3-months') {
                            const date = new Date();
                            date.setHours(0, 0, 0, 0);
                            date.setMonth(date.getMonth() - 3);
                            if (date <= assessmentDate && assessmentDate <= nowDate) {
                                result.push(employee.assessment[key])
                            }
                        } else if (radioValue === 'month') {
                            const date = new Date();
                            date.setHours(0, 0, 0, 0);
                            date.setMonth(date.getMonth() - 1);
                            if (date <= assessmentDate && assessmentDate <= nowDate) {
                                result.push(employee.assessment[key])
                            }
                        } else if (radioValue === 'week') {
                            const date = new Date();
                            date.setHours(0, 0, 0, 0);
                            date.setDate(date.getDate() - 7);
                            if (date <= assessmentDate && assessmentDate <= nowDate) {
                                result.push(employee.assessment[key])
                            }
                        } else if (radioValue === 'all') {
                            if (assessmentDate <= nowDate) {
                                result.push(employee.assessment[key])
                            }
                        }
                    }
                });
                if (result.length > 0) {
                    if (resultStore === 'service') {
                        let serviceResult = this.calculateEmployeeRate(employee.id, result);
                        serviceEmployeesResults.push(serviceResult)
                    } else if (resultStore === 'sales') {
                        let salesResult = this.calculateEmployeeRate(employee.id, result);
                        salesEmployeesResults.push(salesResult);
                    }
                }
            }
        })
        var list = employeeList.slice()
        if (resultStore === 'service') {
            this.filerListByRate(list, serviceEmployeesResults);
            this.setState({
                serviceAssesmentResults: serviceEmployeesResults
            })
        } else if (resultStore === 'sales') {
            this.filerListByRate(list, salesEmployeesResults);
            this.setState({
                salesAssesmentResults: salesEmployeesResults
            })
        }
        return list
    }

    getSales(location) {
        const { salesAssesmentResults } = this.state;
        const { employeesStore } = this.props;
        const userRole = JSON.parse(localStorage.getItem('userRole'));
        db.getSales(location).once('value', snap => {
            var assesmentResults = [];
            const employees = snap.val();
            if (employees !== null) {
                var salesArray = [];
                Object.keys(employees).forEach(key => {
                    if (employeesStore.salesList.find(e => e.id === employees[key].id) === undefined) {
                        salesArray.push(employees[key]);
                    }
                    if (userRole !== "mobile") {
                        const result = this.calculateEmployeeRate(employees[key].id,
                            employees[key].assessment);
                        if (result !== undefined) {
                            const filterResult = salesAssesmentResults.filter((id) => id === result.id)
                            if (filterResult.length === 0) {
                                assesmentResults.push(result)
                            }
                        }
                    }
                });
                const mergedResults = assesmentResults.concat(this.state.salesAssesmentResults)
                this.setState({
                    salesAssesmentResults: mergedResults
                })
                salesArray = employeesStore.salesList.concat(salesArray);
                this.filerListByRate(salesArray, this.state.salesAssesmentResults.slice());
                console.log(salesArray)
                employeesStore.setSalesList(salesArray);
            }
        })
    }

    getServiceEmployees(location) {
        const { serviceAssesmentResults } = this.state;
        const { employeesStore } = this.props;
        const userRole = JSON.parse(localStorage.getItem('userRole'));
        var assesmentResults = [];
        db.getServiceEmployees(location).once('value', snap => {
            const employees = snap.val();
            if (employees !== null) {
                var serviceArray = [];
                Object.keys(employees).forEach(key => {
                    if (employeesStore.serviceList.find(e => e.id === employees[key].id) === undefined) {
                        serviceArray.push(employees[key]);
                    }
                    if (userRole !== "mobile") {
                        let result = this.calculateEmployeeRate(employees[key].id,
                            employees[key].assessment);
                        if (result !== undefined) {
                            if (result !== undefined) {
                                const filterResult = serviceAssesmentResults.filter((id) => id === result.id)
                                if (filterResult.length === 0) {
                                    assesmentResults.push(result)
                                }
                            }
                        }
                    }
                });
                const mergedResults = assesmentResults.concat(this.state.serviceAssesmentResults)
                this.setState({
                    serviceAssesmentResults: mergedResults
                })
                serviceArray = employeesStore.serviceList.concat(serviceArray);
                this.filerListByRate(serviceArray, this.state.serviceAssesmentResults.slice());
                employeesStore.setSerivceList(serviceArray);
            }
        })
    }

    handleFilterRadioButtons(event) {
        const { employeesStore } = this.props;
        this.setState({
            timePeriod: event.target.value,
        })
        var serviceList = this.filterByTimePeriod(event.target.value, employeesStore.serviceList, 'service')
        employeesStore.setSerivceList(serviceList)

        var salesList = this.filterByTimePeriod(event.target.value, employeesStore.salesList, 'sales')

        employeesStore.setSalesList(salesList)

    }

    handleLocalizationRadioButtons(event) {
        this.setState({
            location: event.target.value,
            serviceAssesmentResults: [],
            salesAssesmentResults: []
        })
        const { employeesStore } = this.props;
        employeesStore.setSalesList([]);
        employeesStore.setSerivceList([]);

        event.target.value === 'all' ?
            this.getEmployeesForUser() : this.getEmployeesForUser([event.target.value])
    }

    handleDepartment(event) {
        this.setState({
            department: event.target.value
        });
    }

    handleUpdate() {
        this.getEmployeesForUser()
        this.setState({
            displayAlert: true
        })
    }

    render() {
        const { serviceList } = this.props.employeesStore;
        const { salesList } = this.props.employeesStore;
        const { salesAssesmentResults,
            serviceAssesmentResults,
            department,
            displayAlert
        } = this.state;
        const locations = JSON.parse(localStorage.getItem('locations'));
        const userRole = JSON.parse(localStorage.getItem('userRole'));
        const salesButtonSelected = department === 'sales' ? "sales-selected" : "sales-unselected";
        const serviceButtonSelected = department === 'service' ? "services-selected" : "services-unselected";
        return (
            <div>
                <div className="menu-bar">
                    <div>
                        <label className={salesButtonSelected}><input type="radio" className={"sales-button"} name={"department"} onChange={this.handleDepartment} value={"sales"} defaultChecked /> Dział sprzedaży</label>
                        <label className={serviceButtonSelected}><input type="radio" className={"services-button"} name={"department"} onChange={this.handleDepartment} value={"service"} /> Dział serwisowy</label>
                    </div>
                    {userRole !== 'mobile' ?
                        <Link to={'addEmployee'} className={'add-employee-button'} style={{ textDecoration: 'none', color: 'white' }}> Dodaj pracownika </Link>
                        : null}
                    <button className={'generate-raport-button'} onClick={this.generateRaport}>Generuj raport</button>
                </div>
                <div>
                    {userRole !== "mobile" ?
                        <div className={'employee-filters'}>
                            <div>
                                {locations !== null && locations.length === 2 ? <FewLocations onHandle={this.handleLocalizationRadioButtons} state={this.state.location} /> : null}
                            </div>
                            <TimePeriodFilters onHandle={this.handleFilterRadioButtons} state={this.state.timePeriod} />
                        </div>
                        : null}
                </div>
                {displayAlert ? <label className='edit-employee-alert' onAnimationEnd={() => this.setState({ displayAlert: false })}>
                    Edycja pracownika przebiegła pomyślnie.
                    </label> : null}
                <div className={"employee-list"}>
                    <label className={'employees-title'}>Doradcy.</label>
                    <label className={'pick-employee-title'}>Wybierz doradcę.</label>
                    {department === 'sales' && salesList.length > 0 && <EmployeeList employeeList={salesList}
                        department={"sales"}
                        assesmentResults={salesAssesmentResults}
                        userRole={userRole}
                        update={this.handleUpdate}
                    />}
                    {department === 'service' && serviceList.length > 0 && <EmployeeList employeeList={serviceList}
                        department={"service"}
                        assesmentResults={serviceAssesmentResults}
                        userRole={userRole}
                        update={this.handleUpdate}
                    />}
                </div>
            </div>
        );
    }
}

const EmployeeList = ({ employeeList, department, assesmentResults, userRole, update }) => {
    return (
        <div>
            <Accordion>
                {
                    employeeList.map((employee) => {
                        return (
                            <AccordionItem uuid={employee.id} key={employee.id}>
                                <AccordionItemTitle>
                                    <div className="employee-title"> {employee.name + ' ' + employee.surname}
                                        <img className="unselected-arrow" alt='' src={unselectedArror} height={12} width={12} />
                                        {assesmentResults.map((result) => {
                                            return (result.id === employee.id && userRole !== 'mobile' ?
                                                <div className={'assessment-result-row'} key={'assasment' + employee.id}>
                                                    {'Średnia ocena: ' + result.result}
                                                </div>
                                                : null
                                            )
                                        })
                                        }
                                    </div>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <div className="employee-details">
                                        <Employee employee={employee} department={department} updateList={update} />
                                    </div>
                                </AccordionItemBody>
                            </AccordionItem>
                        )
                    })
                }
            </ Accordion>
        </div>
    )
}

const TimePeriodFilters = ({ onHandle, state }) => {
    const allSelected = state === 'all' ? 'filter-option-selected' : 'filter-option-label'
    const threeMonthsSelected = state === '3-months' ? 'filter-option-selected' : 'filter-option-label'
    const monthSelected = state === 'month' ? 'filter-option-selected' : 'filter-option-label'
    const weekSelected = state === 'week' ? 'filter-option-selected' : 'filter-option-label'

    return (
        <div className={"time-period-filters"}>
            <p className={'time-period-filter-title'}>Zdefiniuj okres.</p>
            <label className={allSelected}><input type="radio" className={'filter-option'} name={"date"} value={"all"} onChange={onHandle} defaultChecked />Cały okres</label>
            <label className={threeMonthsSelected}><input type="radio" className={'filter-option'} name={"date"} value={"3-months"} onChange={onHandle} />Ostatnie 3 miesiące</label>
            <label className={monthSelected}><input type="radio" className={'filter-option'} name={"date"} value={"month"} onChange={onHandle} />Ostatni miesiąc</label>
            <label className={weekSelected}><input type="radio" className={'filter-option'} name={"date"} value={"week"} onChange={onHandle} />Ostatni tydzień</label>
        </div>
    )
}

const FewLocations = ({ onHandle, state }) => {
    const allFilterSelected = state === 'all' ? 'filter-option-selected' : 'filter-option-label'
    const poznanSelected = state === 'poznan' ? 'filter-option-selected' : 'filter-option-label'
    const piasecznoSelected = state === 'piaseczno' ? 'filter-option-selected' : 'filter-option-label'
    return (
        <div className={'few-location-filters'}>
            <p className={'few-location-filters-title'}>Zdefiniuj lokalizację.</p>
            <label className={allFilterSelected}><input type="radio" className={'filter-option'} name={"locations"} value={"all"} onChange={onHandle} defaultChecked />Wszystkie lokalizacje</label>
            <label className={poznanSelected}><input type="radio" className={'filter-option'} name={"locations"} value={"poznan"} onChange={onHandle} />Poznań</label>
            <label className={piasecznoSelected}><input type="radio" className={'filter-option'} name={"locations"} value={"piaseczno"} onChange={onHandle} />Piaseczno</label>
        </div>
    )
}
const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition),
    inject('employeesStore'),
    observer
)(Home);