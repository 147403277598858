import React, { Component } from 'react';
import withAuthorization from "../Session/withAuthorization";
import { compose } from "recompose";
import { storage } from '../../firebase';
import { db } from '../../firebase';
import b64toBlob from 'b64-to-blob';
import { withRouter } from 'react-router-dom';
import { ImageModal } from './ImageModal';
import addEmployeePhoto from '../../assets/Add-photo2.png'
import './add-employee.css';

export class AddEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageName: null,
      image: null,
      location: 'poznan',
      error: null,
      showModal: false,
      displayAlert: false,
    };
    this.addEmployee = this.addEmployee.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.savePhoto = this.savePhoto.bind(this);
  }

  addEmployee(e) {
    e.preventDefault();
    const name = this.getName.value;
    const surname = this.getSurname.value;
    const position = this.getPosition.value;
    const department = this.getDepartment.value;
    this.uploadImage().then(() => {
      db.doCreateEmployee(name, surname, this.state.imageName, position, department, this.state.location);
      this.getName.value = '';
      this.getSurname.value = '';
      this.getPosition.value = '';
      this.getDepartment.value = 'sales';
      this.setState({
        image: null,
        imageName: null,
        error: null,
        displayAlert: true
      })
    }).catch((error) => {
      this.setState({ error: error })
    })
  }

  savePhoto = (photo) => {
    this.setState({
      error: null,
      imageName: photo.imageName,
      image: photo.image
    })
  }

  uploadImage() {
    return new Promise((resolve, reject) => {
      const { image } = this.state;
      const { imageName } = this.state;
      if (imageName === null && image === null) {
        const error = new Error("Zdjęcie nie zostało dodane")
        reject(error)
      } else {
        var imageData = image.substring(image.indexOf(',') + 1);
        var contentType = image.substring(image.indexOf(':') + 1, image.indexOf(';'));
        var convertedImage = b64toBlob(imageData, contentType);
        storage.uploadEmployeeImage(convertedImage, imageName).then(() => {
          resolve()
        })
      }
    })
  }

  getLocation(event) {
    this.setState({
      location: event.target.value
    })
  }

  render() {
    const locations = JSON.parse(localStorage.getItem('locations'))
    const userRole = JSON.parse(localStorage.getItem('userRole'));
    const {
      error,
      image,
      displayAlert
    } = this.state;
    const imageSrc = image !== null ? image : addEmployeePhoto
    return (
      <div className="home-add-employee">
        {userRole !== 'mobile' ?
          <div>
            <div>
              {displayAlert ? <label className='add-employee-alert-label'
                onAnimationEnd={() => this.setState({ displayAlert: false })}>Pracownik został popranie dodany.
              </label> : null}
            </div>
            <div>
              <label className='add-employee-title'>Dodaj pracownika.</label> <br />
              <label className='add-employee-subtitle'>Prawocownik pojawi się na liście ocen.</label>
              <div className='add-employee-form'>
                <form onSubmit={this.addEmployee}>
                  <img className="add-employee-photo" alt="" src={imageSrc} height={160} width={160} onClick={() => this.setState({ showModal: true })} />
                  <ImageModal photo={this.savePhoto} showModal={this.state.showModal} closeModal={() => this.setState({ showModal: false })} />
                  <div className='add-employee-labels'>
                    <label className='add-employee-label'>Imię</label> <br />
                    <label className='add-employee-label'>Nazwisko</label> <br />
                    <label className='add-employee-label'>Dział</label> <br />
                    <label className='add-employee-label'>Stanowisko</label> <br />
                    <label className='add-employee-label'>Lokalizacja</label>
                  </div>
                  <div className='add-employee-values'>
                    <input className='add-employee-textfield' required type="text" ref={(input) => this.getName = input} /><br />
                    <input className='add-employee-textfield' required type="text" ref={(input) => this.getSurname = input} /><br />
                    <select className='add-employee-textfield' ref={(input) => this.getDepartment = input}>
                      <option value="sales">Sprzedaż</option>
                      <option value="service">Serwis</option>
                    </select> <br />
                    <input className='add-employee-textfield' required type="text" ref={(input) => this.getPosition = input} /><br />
                    <div>
                      {locations.length > 1
                        ?
                        <select className='add-employee-textfield' value={this.state.location} onChange={this.getLocation}>
                          <option value={locations[0]}>Poznań</option>
                          <option value={locations[1]}>Piaseczno</option>
                        </select>
                        :
                        () => this.setState({ location: locations[0] })
                      }
                    </div>
                  </div>
                  <button className='add-employee-button' type="submit">Dodaj</button>
                </form>
              </div>
              {error && <label className='add-employee-error-label'>{error.message}</label>}
            </div>
          </div>
          : null}
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default compose(
  withRouter,
  withAuthorization(authCondition),
)(AddEmployee);

