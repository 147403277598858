import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { PasswordForgetLink } from '../PasswordForget';
import { auth, db } from '../../firebase';
import * as routes from '../../constants/routes';
import { inject, observer } from 'mobx-react';
import './sign-in.css';

const SignInPage = ({ history }) =>
  <div className="login">
    <h1 className="sign-in-title">Logowanie do panelu administratora.</h1>
    <div className="login-form">
      <SignInForm history={history} />
      <PasswordForgetLink />
    </div>
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

@inject('sessionStore')
@observer
class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then((user) => {
        this.setState(() => ({ ...INITIAL_STATE }));
        if (user instanceof Error) {
          throw user
        }
        this.getLocationsForUser(user.user.uid)
          .then(() => {
            this.getUserRole(user.user.uid)
              .then(() => {
                history.push(routes.HOME);
              }).catch((error) => {
                this.setState(updateByPropertyName('error', error));
              })
          })
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  getUserRole(userId) {
    return new Promise((resolve, reject) => {
      db.getUserRole(userId).on('value', snap => {
        var userRole = snap.val()
        localStorage.setItem('userRole', JSON.stringify(userRole));
        resolve()
      })
    })
  }

  getLocationsForUser(userId) {
    return new Promise((resolve, reject) => {
      db.getLocationsForUser(userId).on('value', snap => {
        var locations = snap.val();
        localStorage.setItem('locations', JSON.stringify(locations));
        resolve()
      })
    })
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <div>
          <label className="login-email-label">Adres e-mail</label>
        </div>
        <div>
          <input
            className="email-field"
            value={email}
            onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
            type="text"
          />
          <label className="login-password-label">Hasło</label>
          <input
            className="password-field"
            value={password}
            onChange={event => this.setState(updateByPropertyName('password', event.target.value))}
            type="password"
          />
          <button className="login-button" disabled={isInvalid} type="submit">
            Zaloguj
        </button>
        </div>
        <div>
          {error && <label className='signin-error-label'>Wprowadzony adres e-mail lub hasło są nieprawidłowe.</label>}
        </div>
      </form>
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};