import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { auth } from "../../firebase";
import logo from "../../assets/Mercedes-logo1.png"
import './navigation.css'
import { Link } from 'react-router-dom';

const Navigation = ({ sessionStore }) =>
    <div>
        {sessionStore.authUser
            ? <NavigationAuth />
            : <NavigationNonAuth />
        }
    </div>

const NavigationAuth = () =>
    <div className="nav-bar">
        <Link to={'/employeeList'}><img className="img-logo" alt="" src={logo} /></Link>
        <button className="btn-logout" onClick={auth.doSignOut}>Wyloguj</button>
        <Link to={'/account'} style={{ textDecoration: 'none', color: 'white' }} className='link-account'>Ustawienia</Link>
    </div>

const NavigationNonAuth = () =>
    <div className="nav-bar">
        <img className="img-logo" alt="" src={logo} />
    </div>

export default compose(
    inject('sessionStore'),
    observer
)(Navigation);
