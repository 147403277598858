import { observable, action } from 'mobx';

class EmployeesStore {
    @observable salesList = [];
    @observable serviceList = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action setSalesList = salesList => {
        this.salesList = salesList;
    }

    @action setSerivceList = serviceList => {
        this.serviceList = serviceList;
    }

    @action removeSaleEmployee = saleEmployee => {
        var index = this.salesList.indexOf(saleEmployee);
        if (index > -1) {
            this.salesList.splice(index, 1);
        }
    }

    @action removeServiceEmployee = serviceEmployee => {
        var index = this.serviceList.indexOf(serviceEmployee);
        if (index > -1) {
            this.serviceList.splice(index, 1);
        }
    }
}

export default EmployeesStore;