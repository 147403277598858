import { auth, authInvite } from './firebase';
import { sessionPersistence } from "./firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  authInvite.createUserWithEmailAndPassword(email, password);
  authInvite.signOut();

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
    auth.setPersistence(sessionPersistence)
        .then(function() {
            return auth.signInWithEmailAndPassword(email, password);
        })
        .catch(function(error) {
            return error;
        });


// Sign out
export const doSignOut = () =>
  auth.signOut();

// Password Reset
export const doPasswordReset = (email) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) => 
  auth.currentUser.updatePassword(password);

