import { storage } from './firebase';


export const uploadEmployeeImage = (image, imageName) =>
    storage.ref(`images/${imageName}`).put(image);

export const getEmployeeImage = (imageName) =>
    storage.ref(`images/${imageName}`).getDownloadURL();

export const removeEmployeeImage = (imageName) =>
    storage.ref(`images/${imageName}`).delete();
