import React from 'react';
import withAuthorization from "../Session/withAuthorization";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import ReactModal from 'react-modal';
import Dropzone from 'react-dropzone';
import ReactAvatarEditor from 'react-avatar-editor';
import './image-modal.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        height: '93%',
        width: '680px',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#1F1F1F'
    }
};

export class ImageModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            image: null,
            zoom: "1",
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.savePhoto = this.savePhoto.bind(this);
        this.cancelPhoto = this.cancelPhoto.bind(this);
    }

    handleCloseModal() {
        this.props.closeModal();
        this.setState({
            image: null,
            zoom: 1
        });
    }

    savePhoto = () => {
        const fileName = Date.now() + this.state.image.name;
        this.props.photo({
            image: this.editor.getImage().toDataURL(),
            imageName: fileName
        });
        this.props.closeModal();
        this.setState({
            image: null,
            zoom: 1
        });
    }

    cancelPhoto() {
        this.setState({
            image: null,
            zoom: 1
        });
    }

    handleDrop = dropped => {
        this.setState({ image: dropped[0] })
    }

    setEditorRef = (editor) => this.editor = editor

    render() {
        return (
            <div>
                <ReactModal
                    style={customStyles}
                    isOpen={this.props.showModal}
                    ariaHideApp={false}>
                    <label className='add-photo-title'>Dodaj zdjęcie pracownika</label>
                    <button className='close-modal-button' onClick={this.handleCloseModal}>Zamknij</button>
                    <div className='image-with-preview'>
                        <div className='avatar-editor'>
                            <Dropzone
                                onDrop={this.handleDrop}
                                disableClick
                                style={{ width: '350px', height: '350px' }}>
                                {this.state.image !== null ? <div> <ReactAvatarEditor
                                    ref={this.setEditorRef}
                                    image={this.state.image}
                                    scale={Number(this.state.zoom)}
                                    borderRadius={200}
                                    color={[60, 60, 60, 0.75]}
                                    border={0.1}
                                    style={{
                                        width: "350px",
                                        height: "auto",
                                        backgroundColor: "#FFFFFF",
                                    }}
                                />
                                    <div className="slidecontainer">
                                        <input type="range"
                                            step={0.05}
                                            min={1}
                                            max={2.5}
                                            value={this.state.zoom}
                                            onChange={(e) => this.setState({ zoom: e.target.value })}
                                            className="slider" />
                                    </div>
                                    <div className='image-editor-action-buttons'>
                                        <button className='save-photo-button' onClick={this.savePhoto} disabled={!this.state.image}>Zapisz</button>
                                        <button className='cancel-photo-button' onClick={this.cancelPhoto} disabled={!this.state.image}>Anuluj</button>
                                    </div>
                                </div>
                                    : <div>
                                        <input type='file' className='select-photo-input' onChange={(input) => this.setState({ image: input.target.files[0] })} ref='photoInput' />
                                        <label className='select-photo-label' onClick={() => this.refs.photoInput.click()}>Przeciągnij i upuść zdjęcie tutaj lub <br /> Wybierz zdjęcie z dysku</label>
                                    </div>}
                            </Dropzone>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}
const authCondition = (authUser) => !!authUser;

export default compose(
    withRouter,
    withAuthorization(authCondition),
)(ImageModal);